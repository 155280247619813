$(function(){ 
  let _w=$(window).width();
  const //$kv=$('.kv'),
        //_kv_h=$kv.outerHeight(true),
        $menu_ul=$('.menu'),
        $menu_li=$menu_ul.find('li'),
        $fixed_menu_ul=$('.fixed_menu'),
        $fixed_menu_li=$fixed_menu_ul.find('li'),
        $nav_ul=$('.jump_menu'),
        $nav_li=$nav_ul.find('li')
  const $htmlBody=$('html,body'),
        $section=$('.jump').children('section')
  $(window).on('scroll',function(){
    //- 視窗滾過kv時固定選單
  // console.log(_kv_h)
    let _st=$(window).scrollTop()
    //- 經過每個區塊, 屬於該區塊範圍的選單自己加上selected
    for(let i=0;i<$section.length;i++){
      if(_st+150>Math.floor($section.eq(i).offset().top)){
        $menu_li.eq(i).siblings().removeClass('selected')
        $menu_li.eq(i).addClass('selected')
      }else{
        $menu_li.eq(i).removeClass('selected')
      }
    }
    // console.log(_st)
  })

  //- 按選單來視窗滾動
  // $nav_li.click(function(e){
  //   //e.preventDefault();
  //   var _index=$(this).index(),
  //       _top = $section.eq(_index).offset().top
  //   if(_w<800){
  //     $htmlBody.animate({
  //         scrollTop:_top-60
  //     },800)
  //   }else{
  //     $htmlBody.animate({
  //         scrollTop:_top-130
  //     },800)
  //   }
  // })        
  $menu_li.click(function(){
    var _index=$(this).index(),
        _top = $section.eq(_index).offset().top
    if(_w<800){
      $htmlBody.animate({
          scrollTop:_top-43
      },800)
    }else{
      $htmlBody.animate({
          scrollTop:_top
      },800)
    }
  })        
  // $fixed_menu_li.click(function(e){
  //   e.preventDefault();
  //   var _index=$(this).index(),
  //       _top = $section.eq(_index).offset().top
  //   if(_w<800){
  //     $htmlBody.animate({
  //         scrollTop:_top-46
  //     },800)
  //   }else{
  //     $htmlBody.animate({
  //         scrollTop:_top-97
  //     },800)
  //   }
  // })  
  


})
