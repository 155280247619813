$(function(){


  $(window).resize(function(){
      // resize時強制關閉選單，叉叉改成漢堡；
      $('.menu_switch').removeClass('opened');
      $('.menu_switch').html("<i class='fa-solid fa-bars'></i>");

      let _winW=$(window).innerWidth();
      if(_winW>960){
        $('.nav_group').show();
        // 解除點擊事件處理函數綁定
        $('.menu li a').off('click');
      }else{
        //resize時選單就關閉
        $('.nav_group').hide();
        // 解除點擊事件處理函數綁定
        $('.menu li a').off('click');
        $('.menu li a').click(function(){
          //選單會縮回
          $('.nav_group').slideToggle();  
          if($('.menu_switch').hasClass('opened')){
              // 選單關著，叉叉改成漢堡；
              $('.menu_switch').removeClass('opened');
              $('.menu_switch').html("<i class='fa-solid fa-bars'></i>");
              //  $('.nav_group').hide();
              console.log('open')
          }else{
              // 選單開著，漢堡改成叉叉；
              $('.menu_switch').addClass('opened');
              $('.menu_switch').html("<i class='fa-solid fa-xmark'></i>");
              // $('.nav_group').hide();
              console.log('close')
          }
        })
      }
  }).resize();

  var _winW=$(window).innerWidth();
  if(_winW>960){
    $('.nav_group').show();
  }else{
    // 解除點擊事件處理函數綁定，以免重複綁定造成多次觸發
    $('.menu li a').off('click');
    //手機版中，按下選項, 整個選單會縮回
    $('.menu li a').click(function(){
        //選單會縮回
        $('.nav_group').slideToggle();
        //console.log('a')
        // 
        if($('.menu_switch').hasClass('opened')){
            // 選單關著，叉叉改成漢堡；
            $('.menu_switch').removeClass('opened');
            $('.menu_switch').html("<i class='fa-solid fa-bars'></i>");
            //  $('.nav_group').hide();
            console.log('open')
        }else{
            // 選單開著，漢堡改成叉叉；
            $('.menu_switch').addClass('opened');
            $('.menu_switch').html("<i class='fa-solid fa-xmark'></i>");
            // $('.nav_group').hide();
            console.log('close')
        }
    })

  }
  $('ul.menu').show();
  $('.menu_switch').click(function(){
      $('.nav_group').slideToggle();
      //console.log('aa')
      var $this=$(this)
      if($this.hasClass('opened')){
          // 選單關著，叉叉改成漢堡；
          $this.removeClass('opened');
          $this.html("<i class='fa-solid fa-bars'></i>");
      }else{
          // 選單開著，漢堡改成叉叉；
          $this.addClass('opened');
          $this.html("<i class='fa-solid fa-xmark'></i>");
      }
  })
})